import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"

const EnableDevMode = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  if (typeof document !== "undefined") {
    document.cookie =
      "isDevModeEnabled=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/"
  }

  return (
    <Layout location={location} title={siteTitle}>
      <p>Dev mode has been enabled.</p>
    </Layout>
  )
}

export default EnableDevMode

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
